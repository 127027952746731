.rbc-event.bg-blue, .rbc-day-slot .rbc-background-event.bg-blue {
    --tw-bg-opacity: 1;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity));
}

.rbc-event.bg-green, .rbc-day-slot .rbc-background-event.bg-green {
    --tw-bg-opacity: 1;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
}

.rbc-event.border-green, .rbc-day-slot .rbc-background-event.border-green {
    --tw-bg-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity));
}

.rbc-event.border-blue, .rbc-day-slot .rbc-background-event.border-blue {
    --tw-bg-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity));
}

.rbc-event.bg-yellow, .rbc-day-slot .rbc-background-event.bg-yellow {
    --tw-bg-opacity: 1;
    background-color: #F59E0B;
}

.rbc-event.border-yellow, .rbc-day-slot .rbc-background-event.border-yellow {
    --tw-bg-opacity: 1;
    border-color: #B45309;
}

.rbc-event.free-slot, .rbc-day-slot .rbc-background-event.free-slot {
    background-color: rgba(0, 0, 0, 0.20);
    border-color: #666;
    pointer-events: none;
}

.rbc-time-view-resources .rbc-row-resource .rbc-header {
    padding: 10px;
    font-weight: 500;
    font-size: 16px;
}
